<template>
  <div id="app">
    <!-- tab -->
    <van-sticky>
      <!-- 用户 -->
      <div class="user shadow clear" style="box-shadow: 0px 0 0 #ededed;">
        <div class="user_msg_all clear" v-if="customerUser">
          <div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>
          <div class="user_msg">
            <span v-if="customerUser.businessCustomerUser">{{ customerUser.businessCustomerUser.name }}</span>
            <label
                v-if="customerUser.businessCustomerUser">{{
                customerUser.businessCustomerUser.business.name
              }}，{{ customerUser.businessCustomerUser.structure.name }}</label>
          </div>
<!--          <div class="user_r" @click="onClickJumpSwitch">-->
<!--            <div>-->
<!--              <img src="../../assets/images/user_change.png" alt=""> 切换用户-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>

<!--      <div class="orderTab">-->
<!--        <ul class="clear">-->
<!--          <li :class="[ tab == 1 ? 'now' : '' ]" @click="onClickChangeTab(1)">未报名</li>-->
<!--        </ul>-->
<!--      </div>-->
    </van-sticky>

    <!-- 未报名 -->
    <div>
      <!--      <van-pull-refresh v-model="refreshing1" @refresh="onRefresh" style="min-height: 94vh; padding-bottom: 50px;">-->
      <van-pull-refresh v-model="refreshing1" @refresh="onRefresh" style="min-height: 94vh;">
        <van-list
            v-model="loading1"
            :finished="finished1"
            finished-text="没有更多了"
            @load="onLoad"
        >
          <div v-for="(item, index) in list1" :key="index">
            <div class="orderList">
              <div @click="routeToDetail(item)">
                <div class="orderList_t" style="display: flex; align-items: center;">
                  <div style="line-height: 16px; font-size: 16px; font-weight: bold; flex: 1;">{{
                      item.name
                    }}
                  </div>
                  <div :class="getTradingStateClass(item.tradingState)">{{ getTradingState(item.tradingState) }}</div>
                </div>
                <div class="orderList_s_pb0">
                  <ul>
                    <li class="clear">
                      <span>用餐时间：</span>
                      <label>{{ item.cycleStart | moment('yyyy年MM月DD日') }}</label>
                      <label>{{ ' - ' }}</label>
                      <label>{{ item.cycleEnd | moment('yyyy年MM月DD日') }}</label>
                    </li>
                    <li class="clear">
                      <span>就餐价格：</span>
                      <label>{{ formatAmountAndInitZero(item.singlePrice) + '元' }}</label>
                    </li>
                    <li class="clear">
                      <span>就餐天数：</span>
                      <label>{{ (item.mealDays ? item.mealDays : '-') + '天' }}</label>
                    </li>
                    <li class="clear">
                      <span>就餐总额：</span>
                      <label>{{ formatAmountAndInitZero(item.totalAmount) + '元' }}</label>
                    </li>
                  </ul>
                </div>
              </div>
              <div style="text-align: right; padding: 0 12px 12px 0;">
                <button v-if="showCancelApplyBtn(item.tradingState)"
                        style="min-height: 28px; color: #A1A1A1; background: #ffffff; border: #f1f1f1 1px solid; border-radius: 50px;"
                        @click="onClickCancelApply(item, index)">
                  取消报名
                </button>
                <button v-if="showRechargeBtn(item.tradingState)"
                        style="min-height: 28px; color: #ff6666; background: #ffffff; border: #ff6666 1px solid; border-radius: 50px; margin-left: 12px;"
                        @click="onClickRecharge(item)">
                  缴费支付
                </button>
                <button v-if="showApplyBtn(item.tradingState)"
                        style="min-height: 28px; color: #34AC40; background: #ffffff; border: #34AC40 1px solid; border-radius: 50px; margin-left: 12px;"
                        @click="onClickApply(item, index)">
                  报名搭食
                </button>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>

    </div>

    <!-- 底部按钮 -->
    <!--    <div-->
    <!--        style="width: 100%; height: 13vw; display: flex; background: #fff; position: fixed; left: 0; bottom: 0;">-->
    <!--      <button-->
    <!--          style="height: 9vw; min-width: 108px; padding: 0 16px; margin-top: 2vw; background-image: linear-gradient(to right, #39d148 , #34ac40); color: #fff; border: none; box-sizing: border-box; margin-right: 8px; border-radius: 50px; border-bottom-right-radius: 50px; position: absolute; right: 0;"-->
    <!--          @click="onClickConfirm()">-->
    <!--        去报名-->
    <!--      </button>-->
    <!--    </div>-->

    <!-- 报名结果-->
    <van-dialog v-model="showResult" confirm-button-color="#34ac40" confirm-button-text="缴费"
                @confirm="onDialogClickConfirmRouteToRecharge"
                :show-confirm-button="showDialogConfirmButton"
                show-cancel-button cancel-button-text="关闭" cancel-button-color="#A1A1A1">
      <div class="feedback_in" style="padding: 24px 0 24px 0">
        <div style="text-align: -webkit-center;">
          <div style="height: 100px; width: 100px;" :style="{backgroundImage:`url(${resultIconUrl})`}"/>
        </div>
        <div style="color: #222222; margin-top: 4px;">{{ resultState }}</div>
        <div v-if="resultTitle !== ''" style="color: #222222; font-size: 12px; line-height: 12px; margin-top: 4px;">
          {{ resultTitle }}
        </div>
        <div v-if="resultCreatedBy !== ''" style="color: #A1A1A1; font-size: 12px; line-height: 12px; margin-top: 8px">
          {{
            '提交人: ' + resultCreatedBy
          }}
        </div>
        <div style="color: #A1A1A1; font-size: 12px; line-height: 12px; margin-top: 8px">{{
            '提交时间: ' + resultCreatedTime
          }}
        </div>
      </div>
    </van-dialog>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>{{ loadingContent }}</van-loading>
    </div>

  </div>

</template>

<script>
import Vue from "vue";
import {ACCESS_CustomerUser} from "../../store/mutation-types";
import {post} from "../../utils/http";
import {formatAmountAndInitZero} from "../../utils/util";
import {Dialog} from "vant";
import moment from "moment";

export default {
  name: "apply_recharge_list",
  data() {
    return {
      loading: false,
      loadingContent: '加载中...',
      tab: 1,
      isSwitchBusinessCustomerUser: false,

      customerUser: null,
      img: require('../../assets/images/avatar.png'),
      img2: require('../../assets/images/avatar_relevance.png'),

      page1: 0,
      size1: 20,
      total1: 0,
      list1: [],
      loading1: false,
      finished1: false,
      refreshing1: false,

      showResult: false,
      showDialogConfirmButton: false,
      resultIconUrl: require('../../assets/images/ico_success2.svg'),
      resultState: '',
      resultTitle: '',
      resultCreatedBy: '',
      resultCreatedTime: '',
    }
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

    this.customerUser = Vue.ls.get(ACCESS_CustomerUser);
  },
  activated() {
    if (this.isSwitchBusinessCustomerUser) {
      this.customerUser = Vue.ls.get(ACCESS_CustomerUser);
      this.list1 = [];
      this.fetchData(true);
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      // this.$router.push('/account/detail');
      this.$router.push('/customer_user_account');

      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    setLoading(loading, content = '加载中...') {
      this.loading = loading;
      this.loadingContent = content;
    },

    fetchData(isFirst = false) {
      // this.list1 = [{}, {}]

      if (isFirst) {
        this.pagez1 = 0;
      }
      const url = 'api/business/v2/{businessId}/product/bundle/{businessCustomerUserId}/searchAll'
          .replace('{businessId}', this.customerUser.businessId)
          .replace('{businessCustomerUserId}', this.customerUser.businessCustomerUserId);
      post(url, {
        // size: 20,
        // page: this.page1,
        filters: [
          {
            name: 'tradingState', op: '=', value: 'NOT_SIGN_UP'
          }
        ],
        // sort: 'createdTime ASC'
      }).then(res => {
        // this.refreshing1 = false;
        // if (isFirst) {
        //   this.list1 = res.data;
        // } else {
        //   this.list1 = this.list1.concat(res.data);
        // }
        // this.page1++;
        // this.loading1 = false;
        //
        // if (this.list1.length >= res.data.total) {
        //   this.finished1 = true;
        // }
        this.refreshing1 = false;
        this.list1 = res.data;
        this.finished1 = true;

      }).catch(() => {
      })

    },

    onClickJumpSwitch() {
      this.isSwitchBusinessCustomerUser = true;
      this.$router.push({path: '/switch_user', query: {back: true, backPath: 'applyRechargeList'}})
    },
    onClickChangeTab(tab) {
      if (tab === this.tab) {
        return;
      }
      this.tab = tab;
      if (this.tab === 1) {
        // this.loading1 = true;
        // this.finished1 = false;
        // this.fetchData();
      } else if (this.tab === 3) {
        // this.loading3 = true;
        // this.finished3 = false;
        // this.fetchDataWithPendingPayment();
      } else {
        // this.loading2 = true;
        // this.finished2 = false;
        // this.fetchDataAll();
      }
    },
    onClickConfirm() {
    },
    onClickApply(item, index) {
      if (!item) {
        return;
      }

      Dialog.confirm({
        message: '确认报名?',
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(() => {
        this.setLoading(true, '提交中...');
        const url = 'api/business/v2/{businessId}/product/bundle/signUp'.replace('{businessId}', this.customerUser.businessId);
        post(url, {
          businessCustomerUserId: this.customerUser.businessCustomerUserId,
          productBundleIdList: [
            item.id
          ]
        }).then(res => {
          this.resultIconUrl = require('../../assets/images/ico_success2.svg');
          this.resultState = '报名成功';
          this.resultTitle = '请及时进行缴费哦~';
          this.resultCreatedBy = res.data[0].createdBy;
          this.resultCreatedTime = moment(res.data[0].createdTime).format('YYYY-MM-DD HH:mm:ss');
          this.showDialogConfirmButton = true;
          this.showResult = true;
          this.fetchData();
        }).catch(err => {
          this.resultIconUrl = require('../../assets/images/ico_fail.svg');
          this.resultState = '报名失败';
          this.resultTitle = err.message;
          this.resultCreatedTime = moment(err.timestamp).format('YYYY-MM-DD HH:mm:ss');
          this.showDialogConfirmButton = false;
          this.showResult = true;
        }).finally(() => {
          this.setLoading(false);
        })

      }).catch(() => {
      })

    },
    onClickCancelApply(item, index) {
      if (!item) {
        return;
      }

      Dialog.confirm({
        message: '确认取消报名?',
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(() => {
        this.setLoading(true, '提交中...');
        const url = 'api/business/v2/{businessId}/product/bundle/signUp/cancel'.replace('{businessId}', this.customerUser.businessId);
        post(url, {
          businessCustomerUserId: this.customerUser.businessCustomerUserId,
          productBundleId: item.id
        }).then(res => {
          this.resultIconUrl = require('../../assets/images/ico_success2.svg');
          this.resultState = '取消报名成功';
          this.resultTitle = '';
          this.resultCreatedBy = res.data.createdBy;
          this.resultCreatedTime = moment(res.data.createdTime).format('YYYY-MM-DD HH:mm:ss');
          this.showDialogConfirmButton = false;
          this.showResult = true;
          this.fetchData();
          // this.list1[index].tradingState = res.data.

        }).catch(err => {
          this.resultIconUrl = require('../../assets/images/ico_fail.svg');
          this.resultState = '取消报名失败( ' + err.status + '), 请重试';
          this.resultTitle = err.message;
          this.resultCreatedTime = moment(err.timestamp).format('YYYY-MM-DD HH:mm:ss');
          this.showDialogConfirmButton = false;
          this.showResult = true;
        }).finally(() => {
          this.setLoading(false);
        })

      }).catch(() => {
      })

    },
    onClickRecharge(item) {
      this.routeToDetail(item);
    },
    onDialogClickConfirmRouteToRecharge() {
      // this.routeToRecharge();
    },

    onRefresh() {
      this.fetchData();
    },
    onLoad() {
      if (this.page1 === 0) {
        this.fetchData();
      } else {
        this.fetchData(false);
      }
    },

    routeToDetail(item) {
      this.$router.push({path: '/recharge/applyRechargeDetail', query: {id: item.id}})
    },

    showApplyBtn(tradingState) {
      return tradingState === 'NOT_SIGN_UP';
    },
    showCancelApplyBtn(tradingState) {
      return tradingState === 'SIGN_UP_AND_WAIT_RECHARGE' || tradingState === 'SIGN_UP_AND_PAYMENT_NOT_START';
    },
    showRechargeBtn(tradingState) {
      return tradingState === 'SIGN_UP_AND_WAIT_RECHARGE' || tradingState === 'RECHARGE_WAIT';
    },
    getTradingStateClass(tradingState) {
      switch (tradingState) {
        case 'NOT_SIGN_UP':
          return 'product_bundle_trading_state_normal';
        case 'SIGN_UP_AND_WAIT_RECHARGE':
          return 'product_bundle_trading_state_normal';
        case 'SIGN_UP_AND_PAYMENT_NOT_START':
          return 'product_bundle_trading_state_other';
        case 'COMPLETE_RECHARGE':
          return 'product_bundle_trading_state_normal';

        case 'SIGN_UP_NOT_START':
          return 'product_bundle_trading_state_other';
        case 'SIGN_UP_OVERTIME':
          return 'product_bundle_trading_state_other';

        case 'RECHARGE_NOT_START':
          return 'product_bundle_trading_state_other';
        case 'RECHARGE_WAIT':
          return 'product_bundle_trading_state_normal';
        case 'RECHARGE_OVERTIME':
          return 'product_bundle_trading_state_other';

        default:
          return '';
      }
    },
    getTradingState(tradingState) {
      switch (tradingState) {
        case 'NOT_SIGN_UP':
          return '未报名';
        case 'SIGN_UP_AND_WAIT_RECHARGE':
          return '已报名待缴费';
        case 'SIGN_UP_AND_PAYMENT_NOT_START':
          return '已报名未开始缴费';
        case 'COMPLETE_RECHARGE':
          return '已缴费';

        case 'SIGN_UP_NOT_START':
          return '报名未开始';
        case 'SIGN_UP_OVERTIME':
          return '报名已截止';

        case 'RECHARGE_NOT_START':
          return '缴费未开始';
        case 'RECHARGE_WAIT':
          return '待缴费';
        case 'RECHARGE_OVERTIME':
          return '缴费已截止';

        default:
          return '';
      }
    },
    formatAmountAndInitZero(amount) {
      return formatAmountAndInitZero(amount);
    }
  }
}
</script>

<style scoped>
.orderTab {
  height: 48px;
  line-height: 24px;
  text-align: center;
  background: #fff;
}

.orderTab ul li {
  width: 20%;
  height: 48px;
  float: left;
  color: #9c9c9c;
  position: relative;
  padding-top: 12px;
}

.orderTab ul li span {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: inline-block;
  margin-left: 5px;
  background: #fc4353;
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
}

.orderTab ul li.now {
  color: #34ac40;
}

.orderTab ul li.now::after {
  content: '';
  width: 10vw;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -5vw;
  background-image: linear-gradient(to right, #39d148, #34ac40);
  border-radius: 50px;
}

</style>